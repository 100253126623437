<template>
  <div ref="blockRef">
    <h2
      v-if="block.variant?.title"
      v-html="formatTitle(block.variant?.title)"
      :class="`h1 -ml-[1.5%] leading-[0.9] whitespace-nowrap ${block.variant?.title_color.value}`"
      data-gsap-target="title"
    />

    <div class="flex justify-end mt-12 px-4 lg:pl-0 lg:pr-[8%]">
      <div class="max-w-[29rem]">
        <div
          v-if="block.variant?.text"
          v-html="block.variant?.text"
          class="prose text-white text-lg"
          data-gsap-target="text"
        />

        <Buttons
          v-if="block.variant?.buttons"
          :buttons="block.variant?.buttons"
          class="mt-8"
          data-gsap-target="buttons"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Footer } from '~/statamic/types/block';

defineProps<{
  block: Footer;
}>();

let ctx: gsap.Context | null;
const blockRef = ref();

onMounted(() => {
  ctx = useGsap.context((self) => {
    if (typeof self.selector !== 'function') return;

    const titleLeft = self.selector('[data-gsap-target="title"]')[0];
    const titleRight = self.selector('[data-gsap-target="title"] > .text-right')[0];
    const text = self.selector('[data-gsap-target="text"]');
    const buttons = self.selector('[data-gsap-target="buttons"]');

    const timeline = useGsap.timeline({
      scrollTrigger: {
        start: 'top bottom',
        trigger: blockRef.value,
      },
    });

    if (titleLeft) {
      timeline.from(titleLeft, {
        x: -100,
        autoAlpha: 0,
      });
    }

    if (titleRight) {
      timeline.from(titleRight, {
        x: 100,
        autoAlpha: 0,
      }, '-=0.25');
    }

    if (text?.length) {
      timeline.from(text, {
        y: 75,
        scale: 0.75,
        autoAlpha: 0,
      });
    }

    if (buttons?.length) {
      timeline.from(buttons, {
        y: 75,
        scale: 0.75,
        autoAlpha: 0,
      });
    }
  }, blockRef.value);
});

onUnmounted(() => {
  ctx?.revert();
});
</script>
